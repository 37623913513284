<div id="sa-range-selector" class="range-container" style="border: 1px solid transparent;">
    <!-- Horizontal line -->
    <div class="range-bar">
        <!-- Color ranges -->
        <div *ngFor="let range of ranges; let i = index"
             class="range-segment"
             [ngStyle]="{ width: range.value + '%', backgroundColor: range.color}"
        ></div>

        <!-- Draggable markers -->
        <div
                *ngFor="let marker of markers; let i = index"
                class="marker"
                [ngStyle]="{ left: marker.position + '%' }"
                (mousedown)="startDragging($event, i)"
                (touchstart)="startDragging($event, i)"
        ></div>
    </div>
</div>
