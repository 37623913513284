export class SaMoistureRange {

    constructor(json: any) {
        if (json) {
            Object.assign(this, json);
        }
    }

    range1Boundary: number;
    range2Boundary: number;
    range3Boundary: number;
}
