export namespace ToroEnums {

    export enum ToroColor {
        ToroRed = '#cd2027',
        EtTrendUp = ToroRed,
        EtTrendDown = '#3178c6',
    }

    export enum WidgetType {
        Test,
        LynxRunningStations,
        LynxPumpStation,
        TurfGuardSalinity,
        TurfGuardMoisture,
        TurfGuardTemperature,
        WeatherRadar,
        FrostWarning,
        Evapotranspiration,
        LocalWeatherForecast,
        WeatherGraphs,
        GreenSight,
        MyTurfOrders,
        MyTurfMaintenance,
        TurfCloudCourseTrakk,
        TurfCloudLaborStats,
        EquipmentTracking,
        Nsn,
        LynxPumpStations,
        LynxLocalCurrentWeather,
        LynxLocalWeatherGraphs,
        LynxPumpStationsWithGauges,
        Playbooks,
        TaskTrackerLaborSummary,
        TaskTrackerDailyMeasurements,
        TaskTrackerSafety,
        DecisionTree,
        EzLocator,
        LynxStationStatuses,    // aka Lynx Cloud
        GeoFencing,
        PerryWeather,
        Gcsaa,
        GcsaaAlt,
        WeatherAverages,
        SoilScoutSalinity,
        SoilScoutMoisture,
        SoilScoutTemperature,
        Pogo,
        TaskTrackerTurfRad,
        SoilScoutMobile,
        SoilScoutMap,
        Notes,
        SpectrumMoisture,
        DtnWeather
    }

    export enum LanguageCode {
        Bulgarian = 'bg-bg',
        Chinese_Simplified = 'zh-hans',
        Czech = 'cz-cz',
        Danish = 'da-dk',
        Dutch = 'nl-nl',
        English_US = 'en-us',
        French = 'fr-fr',
        German = 'de-de',
        Italian = 'it-it',
        Japanese = 'ja-jp',
        Norwegian = 'nn-no',
        Portuguese = 'pt-pt',
        Russian = 'ru-ru',
        Spanish = 'es-es',
        Swedish = 'sv-se',
        Thai = 'th-th',
        Korean = 'ko-ko',
        Vietnamese = 'vi'
    }

    // https://www.iban.com/currency-codes
    export enum CurrencyCode {
        UsDollar = 'USD',
        Euro = 'EUR',
        MexicanPeso = 'MXN',
        BrazilianReal = 'BRL',
        SwedishKrona = 'SEK',
        Yen = 'JPY',
        Won = 'KRW',                // Korea
        YuanRenminbi = 'CNY',
        Dong = 'VND',               // Vietnam
        RussianRuble = 'RUB',
        BulgarianLev = 'BGN',
        NorwegianKrone = 'NOK',
        CzechKoruna = 'CZK'
    }

    export enum DateFormat {
        MMsDDsYY = 'MM/DD/YY',
        MMhDDhYY = 'MM-DD-YY',
        MMdDDdYY = 'MM.DD.YY',
        DDsMMsYY = 'DD/MM/YY',
        DDhMMhYY = 'DD-MM-YY',
        DDdMMdYY = 'DD.MM.YY',
    }

    export enum MessageSeverity {
        Info = 'info',
        Success = 'success',
        Warn = 'warn',
        Error = 'error'
    }

    export enum ForecastCondition {
        Sunny,
        PartlySunny,
        Cloudy,
        PartlyCloudy,
        Showers,
        Rainfall,
        Thunderstorms,
        Snow,
        Sleet,
        FreezingRain,
        Drizzle,
    }

    export enum UnitsSystem {
        Imperial,
        Metric,
    }

    export enum TemperatureUnits {
        Fahrenheit,
        Celsius,
    }

    export enum GroupAndDecimalSeparator {
        CommaPeriod = 'en-us',
        PeriodComma = 'de-de'
    }

    export enum GaugeColor {
        Low = '#5a93d1',
        Mid = '#6bb878',
        High = '#DF5353',
    }

    export enum TurfGuardSensorType {
        Invalid,
        Temperature,
        Moisture,
        Salinity,
    }

    export enum TurfGuardChartColor {
        Bar = '#739CC6',        // '#739CC6', '#A5AAD9'
        Line = '#739CC6',
        BarText = '#555',
        HighLimit = '#FF0000',
        LowLimit = '#0000FF',
        Alert = '#eb5e25',
    }

    export enum WeatherGraphType {
        DewPoint,
        Wind,
        Temperature,
        Precipitation,
        SolarRadiation,
        RelativeHumidity,
    }

    export enum WeatherGraphChartColor {
        DewPoint = '#5CB85C',
        Temperature = 'darkmagenta',
        Precipitation = 'dodgerblue',
        Wind = 'darkgray',
        SolarRadiation = 'orange',
        RelativeHumidity = 'chocolate'
    }

    export enum PlaybooksNutrientColor {
        N = '#8cc63f',
        P = '#0f75bc',
        K = '#92278f',
        Fe = '#fcb040',
        Mn = '#ed1c24'
    }

    export enum PlaybooksGddColor {
        GDD0 = '#98C455',           // '#8cc63f',
        GDD10 = '#3573B7',          //'#0f75bc',
        GDD32 = '#86308B',          //'#92278f',
        GDD50 = '#F1B357',          //'#fcb040',
    }

    export enum PlaybooksChartType {
        Nutrient,
        Gdd,
    }

    export enum PlaybooksLinkType {
        ApplicationLog = 'APPLICATION LOG',
        Dashboard = 'DASHBOARD',
        Reports = 'REPORTS',
        Documents = 'DOCUMENTS',
        Inventory = 'INVENTORY',
        NotificationsCenter = 'NOTIFICATIONS CENTER'
    }

    export enum AssetTrackingAssetStatus {
        Off = 1,
        Idle = 2,
        Transporting = 3,
        Working = 4,
        NeedsAttention = 5,
        NotUsed = 6,
        Unavailable = 7,
        On = 8
    }

    export enum FrostRisk {
        None,
        High
    }

    export enum GreenSightViewer {
        Course = 'CourseViewer',
        Greens = 'GreenViewer',
        Favorites = 'Favorites'
    }

    export enum HttpStatusCode {
        BadRequest = 400,
        Unauthorized = 401,
        Forbidden = 403,
        FailedDependency = 424,
    }

    export enum SiteDataCategoryType {
        MyTurf,
        Lynx,
        TurfCloud,
        TurfGuard,
        Weather,
        AssetTracking,
        Playbooks,
        Geofence
    }

    export enum AlertType {
        Temperature,
        Moisture,
        Salinity,
        Humidity,
        WindSpeed,
        Pressure,
        Flow,
        FrostLevel,
    }

    export enum AlertThresholdType {
        TooLow,
        TooHigh,
        Other
    }

    export enum AlertSeverity {
        Information,
        Moderate,
        High,
        Severe
    }

    export enum AlertDataLevel {
        Site,
        Course,
        Hole,
        Sensor
    }

    export enum TopologyCategory {
        AreaTopology,
        TGTopology,
        StationActivity,
        Pumps,
        Sensors,
        Weather,
        WeatherRadar,
        LynxWeatherStations,
    }

    export enum CalAmpAccumulators {
        VB_Operating_Status = 0,
        VB_Key_On_Timer = 20,
    }

    export enum CalAmpDeviceStatus {
        Off = 0,
        Idle = 1,
        Transport = 2,
        Work = 3
    }

    export enum AssetTrackingDeviceType {
        Unknown,
        GreensRide,
        GreensWalk,
        OutCross,
        ProCore,
        ProForce,
        ProPass,
        ProSweep,
        Reels,
        Rotary,
        SandPro,
        Sprayers,
        Utility
    }

    export enum AssetTrackingDeviceStatus {
        Unknown = -1,
        Off = 0,
        Idle = 1,
        Transport = 2,
        Work = 3
    }

    export enum CalAmpDeviceType {
        Unknown,
        HMU3640,
        TTU2830
    }

    export enum NsnError {
        Unknown = 0,
        NsnDashAccessError = 1,
        NsnLoginError = 2
    }

    export enum LynxStationStatus {
        Unknown,
        Running,
        RanUnder
    }

    export enum WizardIntegration {
        MyTurf,
        PerryWeather,
        AssetTracking,
        Playbooks,
        TaskTracker,
        EzLocator,
        SoilScout,
        Spectrum
    }

    export enum WizardFaq {
        NotUpdating,
        ShowHideWidgets,
        CustomIntegrations,
        RealTimeUpdates
    }

    export enum WeatherAverageSources {
        Average,
        ToroDtnWeather,
        PerryWeather,
        Lynx,
    }

    export enum WeatherAverageType {
        Temperature,
        Et,
        Humidity,
        Precipitation
    }

    export enum PerryWeatherChartType {
        Precipitation,
        Et
    }

    export enum PwChartDataType {
        PrecipForecast,
        PrecipHistorical,
        EtHistorical
    }

    export enum PogoMoistureRating {
        CriticallyLow,
        Low,
        Good,
        High,
        CriticallyHigh
    }

    export enum PogoTopNav {
        Moisture,
        DistributionUniformity,
        TurfPerformance
    }

    export enum PwChartType {
        Precip,
        Et
    }

    export enum PwChartPeriod {
        Historic,
        Forecast
    }

    export enum SoilScoutSensorType {
        Invalid,
        Temperature,
        Moisture,
        Salinity,
        WaterBalance
    }

    export enum SoilScoutGaugeColor {
        Red = '#DF5353',
        Yellow ='#fbe106',
        Green = '#6bb878',
        Blue = '#5a93d1'
    }

    export enum SoilScoutThresholdTab {
        Moisture,
        Salinity,
        Temperature
    }

    export enum SoilScoutMapView {
        Moisture,
        Temperature,
        Salinity,
        Connectivity
    }

    export enum SpatialAdjustOrganization {
        Austin = 26,
        MissionHills = 27
    }

    export enum SpatialAdjustEntityType {
        ProgramStation = 7,
        Program = 11,
        ProgramHole = 12,
    }

    export enum SaRangeColor {
        R1 = '#E11837',
        R2 = '#F3D43B',
        R3 = '#42CE11',
        R4 = '#3079F0'
    }

    export enum SaVarianceRangeColor {
        R1 = '#E897E5',
        R2 = '#E28EE6',
        R3 = '#DA84E7',
        R4 = '#D27BE9',
        R5 = '#C870EA',
        R6 = '#BF65ED',
        R7 = '#B45BF0',
        R8 = '#A850F3',
        R9 = '#9B45F6',
        R10 = '#8432FC',
        R11 = '#E100FF'
    }
}
