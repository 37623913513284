import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ToroEnums } from '../../../common/enumerations/toro.enums';
import SaRangeColor = ToroEnums.SaRangeColor;

@Component({
    selector: 'toro-color-range',
    templateUrl: './color-range.component.html',
    styleUrls: ['./color-range.component.less']
})
export class ColorRangeComponent implements OnInit {
    private readonly MULTIPLIER: number = 2;
    private readonly MAX_RANGE = 100;

    @Output() dragEnd = new EventEmitter();

    private _range: { from: number, to: number, color: string }[]
    @Input() set range(value: { from: number, to: number, color: string }[]) {
        if (value == null || value.length < 3) { return; }

        this._range = value;

        if (this.ranges == null) {
            this.initRangeSelector();
        }
    }

    get range(): { from: number, to: number, color: string }[] {
        return this._range;
    }

    private isActive = false;
    protected ranges: { value: number, color: string }[];
    protected markers: { position: number }[];
    draggingMarkerIndex: number | null = null;


    // =========================================================================================================================================================
    // Ctor and Lifecycle Hooks
    // =========================================================================================================================================================

    ngOnInit(): void {
        if (this.range != null) {
            this.initRangeSelector();
        }
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private initRangeSelector() {
        const r1Boundary: number = this.range[0].to;
        const r2Boundary: number = this.range[1].to;
        const r3Boundary: number = this.range[2].to;

        // Define ranges
        this.ranges = [
            { value: r1Boundary * this.MULTIPLIER, color: SaRangeColor.R1 },         // 0 - 5
            { value: (r2Boundary - r1Boundary) * this.MULTIPLIER, color: SaRangeColor.R2 },       // 6 - 19
            { value: (r3Boundary - r2Boundary) * this.MULTIPLIER, color: SaRangeColor.R3 },      // 20 - 30
            // { value: 70, color: 'yellow' }      // 31+
        ];

        // Define markers
        this.markers = [
            { position: r1Boundary * this.MULTIPLIER }, // Marker 1
            { position: r2Boundary * this.MULTIPLIER }, // Marker 2
            { position: r3Boundary * this.MULTIPLIER }  // Marker 3
        ];
    }

    // Handle dragging start
    startDragging(event: MouseEvent | TouchEvent, markerIndex: number) {
        event.preventDefault();
        this.draggingMarkerIndex = markerIndex;
    }

    // Handle dragging end
    @HostListener('document:mouseup')
    @HostListener('document:touchend')
    stopDragging() {
        if (this.isActive) {
            this.isActive = false;
            this.draggingMarkerIndex = null;
            this.dragEnd.emit();
        }
    }

    // Handle dragging move
    @HostListener('document:mousemove', ['$event'])
    @HostListener('document:touchmove', ['$event'])
    dragMarker(event: MouseEvent | TouchEvent) {
        if (this.draggingMarkerIndex === null) return;

        this.isActive = true;

        // Determine the cursor position
        const clientX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
        const container = (event.target as HTMLElement).closest('.range-container') as HTMLElement;
        if (!container) return;

        const rect = container.getBoundingClientRect();
        let percentage = Math.max(0, Math.min(this.MAX_RANGE, ((clientX - rect.left) / rect.width) * 100));

        // Update the marker position
        const index = this.draggingMarkerIndex;
        const min = index === 0 ? 0 : this.markers[index - 1].position + 1;
        const max = index === this.markers.length - 1 ? (this.MAX_RANGE - 1) : this.markers[index + 1].position - 1;

        this.markers[index].position = Math.max(min, Math.min(max, percentage));
        this.updateRanges();
    }

    // Update ranges based on marker positions
    updateRanges() {
        this.ranges[0].value = this.markers[0].position;
        this.ranges[1].value = this.markers[1].position - this.markers[0].position;
        this.ranges[2].value = this.markers[2].position - this.markers[1].position;
        // this.ranges[3].value = this.MAX_RANGE - this.markers[2].position;

        const displayValues = [
            0,
            Math.round(this.markers[0].position / 2),
            Math.round(this.markers[1].position / 2),
            Math.round(this.markers[2].position / 2)
        ];

        console.log(`0-${displayValues[1]}%   ${displayValues[1] + 1}-${displayValues[2]}%   ${displayValues[2] + 1}-${displayValues[3]}%   >${displayValues[3]}`);

        this.range[0].to = displayValues[1];

        this.range[1].from = displayValues[1] + 1;
        this.range[1].to = displayValues[2];

        this.range[2].from = displayValues[2] + 1;
        this.range[2].to = displayValues[3];

        this.range[3].from = displayValues[3];
    }

}
